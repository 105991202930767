<template>
  <div class="search">
    <input
      type="text"
      :value="searchText"
      class="search__text"
      placeholder="Поиск"
      @input="$emit('update:searchText', $event.target.value)"
    />

    <close-icon class="search__close" @click="clearText"> </close-icon>
  </div>
</template>

<script>
import CloseIcon from '@/components/atom/html-icon/symbol/CloseIcon.vue';

export default {
  components: {
    CloseIcon,
  },
  emits: ['update:searchText'],

  props: {
    searchText: {
      type: String,
      required: true,
    },
  },

  methods: {
    clearText() {
      this.$emit('update:searchText', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;

  &__text {
    font-size: 18px;
    padding: 0 5px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    outline: none;

    &::placeholder {
      font-size: 18px;
      line-height: 32px;
    }
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0%, -60%);

    &::before,
    &::after {
      position: absolute;
      height: 22px;
      width: 4px;
      left: 11px;
      top: 2px;
    }
  }
}
</style>
