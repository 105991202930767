<template>
  <div ref="communites" class="communites">
    <div class="contenter">
      <div class="table">
        <div class="table__header">
          <search-user v-model:searchText="calcSearchText" class="table__search"></search-user>
        </div>

        <pagination ref="pagination" :target="target" class="table__caption"></pagination>

        <circle-c v-if="getAnimation" class="animation-container__icon"></circle-c>

        <div v-else class="table__body">
          <div
            v-for="item in calcJSON"
            :key="item.id"
            :ref="setRef(item)"
            class="table__row"
            :class="{ select: isSelectClass(item.id) }"
          >
            <div class="table__cell cell-settings__public">
              <a :href="item.group_link" target="_blank" class="link">
                <div class="table__image-size">
                  <img :src="item.photo_link" class="table__icon" />
                </div>
                <div class="display-panel">
                  <div class="panel">
                    <p class="panel__text">{{ item.group_name }}</p>
                  </div>
                </div>
              </a>
            </div>

            <div class="table__cell cell-settings__auditory">
              {{ item.subscrices }}
            </div>

            <div class="table__cell cell-settings__changes">
              <div v-for="(card, index) in item.changes" :key="index" class="card">
                <div class="card__display">
                  <img v-if="card.img" :src="card.img" class="table__icon-admin" />

                  <a
                    v-if="card.img"
                    :href="card.link"
                    target="_blank"
                    class="card__panel panel__text"
                    >{{ card.title }}</a
                  >

                  <p v-else class="card__panel panel__text">{{ card.title }}</p>
                </div>

                <div class="card__display" v-if="card.oldValue !== ''">
                  <p class="card__panel panel__text">{{ card.oldValue }}</p>
                </div>

                <div class="card__display" v-if="card.newValue !== ''">
                  <p class="card__panel panel__text">{{ card.newValue }}</p>
                </div>
              </div>
            </div>

            <div class="table__cell cell-settings__date">
              {{ item.date }}
            </div>
          </div>
        </div>

        <ScrollTop class="test"></ScrollTop>

        <infinity-scroll @intersect="intersected" id="tt" v-if="show" class="observe" />
      </div>
    </div>

    <aside class="settings" :class="{ show: getSettingsState, 'settings-height': getAnimation }">
      <div class="filters">
        <div class="auditory">
          <p class="auditory__title">Аудитория</p>

          <div class="close" @click="resetAuditory"></div>
        </div>

        <double-slider ref="slider" @update:resetInput="resetInput"></double-slider>

        <div class="filters__radio-buttons">
          <p class="filters__title">Тип изменения</p>
          <!-- TODO radiobuttin here -->
          <check-box
            :items="typeChanges"
            class="filters__buttons"
            @emitState="updateCheckBox"
          ></check-box>

          <div class="filters__panel">
            <a class="filters__link" @click="selectAll(true)">
              Все
              <!-- <img src="/icons/common/okey.svg" class="filters__icon" /> -->
              <check-mark class="filters__icon"></check-mark>
            </a>

            <a class="filters__link" @click="selectAll(false)">
              Сброс
              <!-- <img src="/icons/common/cancle.svg" class="filters__icon" /> -->
              <close-icon class="filters__icon"></close-icon>
            </a>
          </div>
        </div>

        <div class="filters__radio-buttons">
          <p class="filters__title">Тип</p>
          <!-- TODO radiobuttin here -->
          <radio-buttons
            :items="type"
            :groupName="'type'"
            class="filters__buttons"
            @updateItem="updateItem"
          ></radio-buttons>
        </div>

        <div v-if="type[2].checked" class="filters__radio-buttons">
          <p class="filters__title">Статус</p>
          <!-- TODO radiobuttin here -->
          <radio-buttons
            :items="status"
            :groupName="'status'"
            class="filters__buttons"
            @updateItem="updateItem"
          ></radio-buttons>
        </div>

        <!-- <check-box
          :items="checkButtons"
          class="filters__checkbox"
          @emitState="updateCheckBox"
        ></check-box> -->
      </div>
    </aside>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import RadioButtons from '@/components/atom/button/radio/RadioButtons.vue';
import CheckBox from '@/components/atom/button/check/CheckBox.vue';
import DoubleSlider from '@/components/common/slider/DoubleSlider.vue';
import Pagination from '../../components/pagination/Pagination.vue';
import CircleC from '@/components/atom/loader/Circle.vue';
import ScrollTop from '@/components/atom/button/scroll/ScrollTop.vue';
import InfinityScroll from '@/components/pagination/InfinityScroll.vue';
import SearchUser from '@/components/common/BaseSearch.vue';
import CheckMark from '@/components/atom/html-icon/symbol/CheckMark.vue';
import CloseIcon from '@/components/atom/html-icon/symbol/CloseIcon.vue';

let debounceCaller;

export default {
  components: {
    RadioButtons,
    CheckBox,
    DoubleSlider,
    Pagination,
    CircleC,
    InfinityScroll,
    ScrollTop,
    SearchUser,
    CheckMark,
    CloseIcon,
  },
  data() {
    return {
      target: null,
      delbuf: '',
      showCatalogs: true,
      loadCount: false,
      show: false,
      searchText: '',
      rotate: 0,
      listShow: null,
      activeLink: null,
      selectedRows: [],
      titles: {
        1: 'Сообщество сменило название',
        2: 'Сообщество получило бан',
        3: 'Сообщество изменило аватарку',
        4: 'Сообщество сменило закрытость',
      },
      options: {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      },
      observer: null,

      typeChanges: [
        {
          id: 1,
          title: 'Названия',
          subtitle: 'name',
          name: 'typeChanges',
          isChecked: true,
        },
        {
          id: 2,
          title: 'Баны',
          subtitle: 'ban',
          name: 'typeChanges',
          isChecked: true,
        },
        {
          id: 5,
          title: 'Аватар',
          subtitle: 'ava',
          name: 'typeChanges',
          isChecked: false,
        },
        {
          id: 7,
          title: 'Закрытость',
          subtitle: 'close',
          name: 'typeChanges',
          isChecked: false,
        },
        {
          id: 8,
          title: 'Верификация',
          subtitle: 'ver',
          name: 'typeChanges',
          isChecked: false,
        },
        {
          id: 9,
          title: 'Администраторы',
          subtitle: 'admin',
          name: 'typeChanges',
          isChecked: false,
        },
      ],

      hashMap: {
        page: 'Сообщество стало Пабликом',
        group: 'Сообщество стало Группой',
        event: 'Сообщество стало Событием',
      },

      type: [
        {
          id: 4,
          title: 'Все',
          settingName: 'all',
          checked: true,
        },
        {
          id: 1,
          title: 'Паблики',
          settingName: 'page',
          checked: false,
        },
        {
          id: 2,
          title: 'Группы',
          settingName: 'group',
          checked: false,
        },
        {
          id: 3,
          title: 'События',
          settingName: 'event',
          checked: false,
        },
      ],

      status: [
        {
          id: 1,
          title: 'Все группы',
          settingName: 'empty',
          checked: true,
        },
        {
          id: 2,
          title: 'Открытые',
          settingName: 'открытаягруппа',
          checked: false,
        },
        {
          id: 3,
          title: 'Закрытые',
          settingName: 'закрытаягруппа',
          checked: false,
        },
      ],

      checkButtons: [
        {
          id: 1,
          title: 'Только официальные',
          name: 'verification',
          isChecked: false,
        },
      ],

      names: {
        Подписчики: 'sub',
        Посетители: 'vis',
        Прирост: 'grow',
        Охват: 'cov',
      },
    };
  },
  computed: {
    ...mapGetters('vkRequests', ['getResultCommunities', 'getGroupCount', 'getAnimation']),
    ...mapGetters('vkConfiguration', ['getSettingsState']),

    calcJSON() {
      let resultItem = {};
      const resultJSON = [];

      this.getResultCommunities.forEach((element) => {
        resultItem = {};
        resultItem.group_name = element.new_name ? element.new_name : element.name;
        resultItem.group_link = element.group_link;
        resultItem.photo_link = element.new_ava ? element.new_ava : element.ava;
        resultItem.changes = [];
        resultItem.date = element.date;
        resultItem.sub_count = element.sub_count;
        resultItem.subscrices = new Intl.NumberFormat('ru-RU').format(+element.sub_count);

        if (element.ban) {
          resultItem.changes.push({
            title: 'Сообщество было забанено',
            changeType: 'ban',
            oldValue: '',
            newValue: '',
          });
        }
        if (element.close) {
          const titleString = Number.parseInt(element.close, 10) === 1
            ? 'Сообщество стало частным'
            : 'Сообщество стало открытым';
          resultItem.changes.push({
            title: titleString,
            changeType: 'close',
            oldValue: '',
            newValue: '',
          });
        }
        if (element.new_name) {
          resultItem.changes.push({
            title: 'Сообщество сменило название',
            changeType: 'newgroup',
            oldValue: element.name,
            newValue: element.new_name,
          });
        }
        if (element.new_ava) {
          resultItem.changes.push({
            title: 'Сообщество изменило аватар страницы',
            changeType: 'ava',
            oldValue: '',
            newValue: '',
          });
        }
        if (element.ver) {
          const titleString = Number.parseInt(element.ver, 10) === 1
            ? 'Сообщество прошло верефикацию'
            : 'Сообщество не верефировано';
          resultItem.changes.push({
            title: titleString,
            changeType: 'close',
            oldValue: '',
            newValue: '',
          });
        }
        if (element.new_typ) {
          resultItem.changes.push({
            title: this.hashMap[element.new_typ],
            changeType: 'type',
            oldValue: '',
            newValue: '',
          });
        }

        if (element.admin && element.admin.length !== 0) {
          element.admin.forEach((el) => {
            const resultAdmin = {
              img: el.photo_link,
              link: el.admin_link,
              title: `${el.first_name} ${el.second_name}`,
              changeType: 'admin',
              oldValue: el.msg,
              newValue: el.desc ? `Описание: ${el.desc}` : '',
            };

            resultItem.changes.push(resultAdmin);
          });
        }

        if (resultItem.changes.length !== 0) {
          resultJSON.push(resultItem);
        }
      });

      return resultJSON;
    },

    takeResultCommunities() {
      this.getResultCommunities.forEach((item) => {
        if (item.index % 50 === 0) {
          this.selectedRows.push(item);
        }
      });
      return this.getResultCommunities;
    },

    // calcCategorys() {
    //   return this.getCategory;
    // },

    calcSearchText: {
      get() {
        return this.searchText;
      },
      set(value) {
        debounceCaller();
        this.searchText = value;
      },
    },
  },
  watch: {
    getAnimation(to) {
      if (to === true) {
        this.loadCount = false;
      }
    },
  },
  methods: {
    ...mapActions('vkRequests', [
      'requestCommunities',
      'requestByDate',
      'requestByType',
      'requestByStatus',
      'requestAllStaticParams',
      'requestCategory',
      'requestByCategory',
      'requestByVerification',
      'requestBySort',
      'requestBySearch',
      'requestByChangeChecked',
      'requestChanges',
      'cancleRequest',
    ]),
    ...mapMutations('vkRequests', [
      'setCurrentDate',
      'setLoadPage',
      'resetJSON',
      'setSearchInput',
      'resetAllParams',
    ]),
    ...mapMutations('routerControl', ['setCurrentActivePage']),
    searchRequest() {
      this.resetJSON();

      this.setSearchInput(this.searchText);
      this.requestBySearch();
    },

    setClass(event) {
      switch (true) {
        case this.activeLink === null:
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          break;
        case this.activeLink !== event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          break;
        case this.activeLink === event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = null;
          break;
        default:
      }

      const resultStr = this.activeLink === null ? '' : this.activeLink.innerText;

      this.requestByCategory(resultStr);
      this.resetInput();
    },

    isSelectClass(currentValue) {
      return currentValue % 50 === 0;
    },

    isSelectRef(currentValue) {
      return currentValue % 50 === 0 ? null : `ref${currentValue}`;
    },

    updateItem(currentId, groupName) {
      this[groupName].forEach((element, index) => {
        if (element.id === currentId) {
          this[groupName][index].checked = true;

          this.callFromFilterRequest(groupName, element.settingName);
          return;
        }
        this[groupName][index].checked = false;
      });
    },
    updateCheckBox(button) {
      // if (button.name === 'verification') {
      //   this.requestByVerification(button.isChecked);
      // }

      if (button.name === 'typeChanges') {
        const result = [];
        this.typeChanges.forEach((item) => {
          if (item.subtitle !== 'ignore') {
            result.push({
              name: item.subtitle,
              isActive: item.isChecked,
            });
          }
        });

        this.requestByChangeChecked(result);
        this.resetInput();
      }
    },

    callFromFilterRequest(groupName, settingName) {
      this.resetInput();
      switch (true) {
        case groupName === 'period':
          this.requestByDate(settingName);
          break;
        case groupName === 'type':
          this.requestByType(settingName);

          if (settingName === 'group') {
            this.showCatalogs = false;
          } else {
            this.showCatalogs = true;
          }
          break;
        case groupName === 'status':
          this.requestByStatus(settingName);

          break;
        default:
      }
    },
    reset() {
      this.requestByCategory('');
    },

    resetAuditory() {
      this.$refs.slider.resetAuditory();
    },

    resetInput() {},

    setRef(currentItem) {
      const result = currentItem.id % 20;
      if (result === 0) {
        this.selectedRows.push(result);
      }

      return currentItem.id % 20 ? null : `ref${currentItem.id}`;
    },

    debounce(f, t) {
      return () => {
        const previousCall = this.lastCall;
        this.lastCall = Date.now();
        if (previousCall && this.lastCall - previousCall <= t) {
          clearTimeout(this.lastCallTimer);
        }
        this.lastCallTimer = setTimeout(() => f(), t);
      };
    },

    selectAll(isSelectAll = false) {
      this.typeChanges.forEach((item, index) => {
        this.typeChanges[index].isChecked = isSelectAll;
      });
      const result = [];
      this.typeChanges.forEach((item) => {
        if (item.subtitle !== 'ignore') {
          result.push({
            name: item.subtitle,
            isActive: item.isChecked,
          });
        }
      });

      this.requestByChangeChecked(result);
      this.resetInput();
    },
    async intersected() {
      if (!this.loadCount) {
        this.loadCount = true;
        return;
      }

      this.$refs.pagination.setNextButton();
    },
  },
  async mounted() {
    this.setLoadPage('requestChanges');
    // this.setCurrentActivePage('another');
    debounceCaller = this.debounce(this.searchRequest, 1000);
    this.listShow = this.$refs.communites.querySelector('.img-show');

    // request page
    // this.requestCategory();
    await this.requestAllStaticParams();
    await this.requestChanges();
    this.show = true;
    this.target = document.querySelector('.table__header');
  },
  beforeUnmount() {
    this.cancleRequest('tokenChanges');

    this.resetAllParams();
  },
};
</script>

<style lang="scss" scoped>
$wigth-cell: 30%;

.communites {
  display: flex;
  justify-content: center;
  align-items: stretch;

  position: relative;
}

.contenter {
  width: calc(100% - 375px);
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0 12px;

  // flex-basis: 20%;
  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;
  height: auto;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #dee5eb;

  // .table__header
  &__header {
    display: flex;
    background: #dee5eb;

    padding: 0 5px;
  }

  // .table__caption
  &__caption {
    display: flex;
    justify-content: flex-end;
    height: 30px;

    margin: 0 5px 0 0;

    position: sticky;
    top: 0;
    width: fit-content;
    align-self: flex-end;
  }

  &__search {
    width: 100%;
    font-size: 18px;
    padding: 10px 5px 10px;

    &::placeholder {
      font-size: 18px;
      line-height: 32px;
    }
  }

  &__image-size {
    width: 50px;
    height: 50px;

    margin: 0 5px 0 0;
  }

  &__icon {
    width: 50px;
    height: 50px;
  }

  &__icon-admin {
    width: 45px;
    height: 45px;

    border-radius: 50%;
    margin: 0 5px 0 0;
  }

  &__arrow {
    transform: rotate(-180deg);
  }

  &__img {
    display: none;
    width: 10px;
    height: 10px;
  }

  // .table__title
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 60px;

    padding: 0 0 0 5px;

    color: #45688e;
  }

  // .table__body
  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    // justify-content: flex-start;
    // align-items: center;

    border-top: 1px solid #dee5eb;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    border-bottom: 1px solid #dee5eb;
  }

  // .table__cell
  &__cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0 5px 5px;
    min-height: 64px;
    margin: 1px 0;

    background: #fff;
  }
}
// settings
.filters {
  //width: 100%;
  width: 350px;

  // .filters__radio-buttons
  &__radio-buttons {
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    margin: 10px 0 0 0;
    width: 100%;
  }

  &__buttons {
  }

  &__title {
    color: #555;
    font-weight: 700;
    font-size: 18px;
  }

  // .filters__checkbox
  &__checkbox {
    margin: 10px 0 0 0;
  }

  &__panel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 10px 0 0 0;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    line-height: 20px;
    font-weight: 700;
    color: #5e82a8;
    margin: 0 10px;

    &:hover .filters__icon {
      opacity: 1;
    }
  }
  &__icon {
    opacity: 0.4;
    margin: 0 10px;
  }
}

.static-info {
  color: #45688e;
  font-weight: 700;
  font-size: 18px;

  margin-top: 18px;
}

.auditory {
  display: flex;
  justify-content: flex-start;

  margin-top: 23px;
  height: 30px;
  align-items: center;
  position: relative;

  // .auditory__title
  &__title {
    color: #555;
    font-weight: 700;
    font-size: 18px;
  }
}
.categories {
  width: 100%;
  // .categories__menu
  &__menu {
  }

  // .categories__link
  &__link {
  }

  // .categories__catalogs
  &__catalogs {
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: " ";
    height: 33px;
    width: 6px;
    background-color: #333;
  }
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.line {
  display: block;
  width: 100%;
  content: "";
  height: 0;
  display: block;
  border-top: 1px solid #d8dfea;
  margin: 10px 0 10px 0;
}

.catalog-folder {
  width: 100%;
}
.link {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  height: auto;
  width: 100%;
  // height: 100%;
}

.img-show {
  display: inline-block;
}

.display-panel {
  display: flex;
  justify-content: flex-start;

  align-items: stretch;
  height: 100%;
  width: 80%;
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 3px;
  width: 100%;

  // .panel__image
  &_align-top {
    justify-content: flex-start;
  }
  &__image {
    width: 20px;
    height: 20px;
  }

  // .panel__text
  &__text {
    text-align: left;
    width: 100%;

    margin: 0; /* Убираем внешний отступ */
    -webkit-line-clamp: 2; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden; /* Обрезаем всё за пределами блока */
    word-break: break-all;
  }

  // .panel__info
  &__info {
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    // height: 10px;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    height: 10px;
    color: #c5c5c5;
    font-size: 11px;
    text-decoration: none;
  }

  // .panel__tags
  &__tags {
    font-size: 10px;
    content: "";
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  // flex-basis: 30%;
  // .card__display
  &__display {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // .card__panel
  &__panel {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
}

.text {
  &__size {
    font-size: 18px;
  }
  &__bold {
    font-weight: 700;
  }
  &__thin {
    font-weight: 300;
  }
  &__color {
    &_link {
      color: #2b587a;
    }
    &_green {
      color: #7ea65a;
    }
    &_red {
      color: #d44f30;
    }
  }
  // TODO rename this
  &__main {
    margin: 0 8px 0 0;
  }

  &__sub {
    // align-self: flex-end;
  }
}

.animation-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  margin: 0 0 10px 0;

  height: 40px;
  width: 100%;

  background: #383f48;
  border: 1px solid #383f48;
  box-sizing: border-box;
  border-radius: 10px;

  &__icon {
    position: relative;
    width: 125px;
    height: 125px;
    margin: 50px auto 0;
  }

  &__hidden {
    display: none;
  }
}
.test {
  position: fixed;
  bottom: 27px;
  right: calc(41vw - 23%);
  z-index: 15;
}
.observe {
  content: "";
  display: flex;
  height: 81px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.justify-self {
  margin: 0 5px 0 auto;
}
.margin {
  margin: 0 0 0 5px;
}
// 1170px
@media (max-width: 1500px) {
  .card {
    // width: 100%;
  }
  .settings {
    padding: 0px 0px 0 5px;

    flex-basis: 50%;
    max-width: 350px;

    height: auto;
  }

  .filters {
    width: 100%;
  }

  .static-info {
    text-align: start;
  }

  // .table {
  //   // .table__cell
  //   &__cell {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     padding: 5px 0 5px;
  //     margin: 1px 0;

  //     background: #fff;
  //   }
  // }
}
@media (max-width: 1300px) {
  .settings {
    position: absolute;
    top: 90px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: 100%;
  }

  .contenter {
    width: 100%;
  }

  .show {
    visibility: visible;
  }
}

.cell-settings {
  &__public {
    width: 40%;
    min-width: 250px;

    @media (max-width: 1650px) {
      width: 35%;
    }
  }
  &__auditory {
    //max-width: 100px;
    min-width: 100px;
    width: 10%;
  }
  &__changes {
    width: 40%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1650px) {
      width: 35%;
    }
  }
  &__date {
    min-width: 100px;
    width: 10%;

    font-weight: 300;
    margin: 0 25px 0 auto;
  }
}

.settings-height {
  height: 100vh;
}
</style>
