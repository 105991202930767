<template>
  <div class="radio-button">
    <label
      class="radio-button__label"
      v-for="(item) in items"
      :key="item.id"
    >
      <input
        type="radio"
        :name="groupName"
        class="radio-button__input"
        :checked="item.checked"
        @change="updateState(item.id)"
      />

      <span class="radio-button__text"> {{ item.title }} </span>
    </label>
  </div>
</template>

<script>
// TODO rename it into radiobuttonslist and send to components
export default {
  data() {
    return {
      bufCount: null,
    };
  },
  emits: ['updateItem'],

  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    groupName: {
      type: String,
      default: 'group',
    },
  },
  methods: {
    updateState(currentId) {
      this.$emit('updateItem', currentId, this.groupName);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;

  position: relative;
  width: 100%;

  // height: 125px;

  &__input {
    display: none;

    &:checked + .radio-button__text::before {
      border: 3px solid #5098ff;
    }

    &:checked + .radio-button__text::after {
      opacity: 1;
    }
  }

  // mb for del
  &__text {
    display: flex;
    position: relative;

    padding: 6px 0 6px 35px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    // color: #c5c5c5;

    &::before {
      content: "";
      position: absolute;
      left: 10px;
      top: 7px;

      width: 15px;
      height: 15px;

      border: 3px solid #999999;
      box-sizing: border-box;
      border-radius: 20px;
    }

    &::after {
      content: "";
      position: absolute;

      left: 14px;
      top: 11px;
      width: 7px;
      height: 7px;

      background: #5098ff;
      border-radius: 20px;

      opacity: 0;
    }

    &:hover {
      background-color: #edf1f5;
    }
  }

  &__label{
    width: 100%;
  }
}
</style>
