<template>
  <div class="content" ref="content">
    <div
      id="my-slider"
      ref="slister"
      :se-min="minAuditory"
      se-step="1"
      :se-min-value="minAuditory"
      :se-max-value="maxAuditory"
      :se-max="maxAuditory"
      class="slider"
      @click="$emit('update:resetInput')"
    >
      <div class="slider-touch-left">
        <span class="spanl span1"></span>
      </div>

      <div class="slider-touch-right">
        <span class="spanr span1"></span>
      </div>

      <div class="slider-line">
        <span class="span2"></span>
      </div>
    </div>
  </div>

  <div class="result">
    <input
      id="sliderCountFrom"
      type="text"
      class="input"
      v-model="minAuditory"
      @input="setAuditory"
    />

    <p class="result__text">-</p>

    <input
      id="sliderCountTo"
      type="text"
      class="input"
      v-model="maxAuditory"
      @input="setAuditory"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Slider from '@/assets/js/slider/doubleSlider';

let newSlidet = null;
let callDebounce = null;

export default {
  emits: ['update:resetInput'],
  computed: {
    ...mapGetters('vkRequests', ['getAuditoryCount', 'calcForWatch']),
  },
  watch: {
    calcForWatch() {
      // it used if we takes new diapazone of auditory
      this.minAuditory = Number.parseInt(this.getAuditoryCount.countFrom, 10);
      this.maxAuditory = Number.parseInt(this.getAuditoryCount.countTo, 10);

      newSlidet.setMin(
        Number.parseInt(this.getAuditoryCount.diapazoneFrom, 10),
      );
      newSlidet.setMax(Number.parseInt(this.getAuditoryCount.diapazoneTo, 10));
    },
  },

  data() {
    return {
      minAuditory: 0,
      maxAuditory: 1000,

      lastCall: null,
      lastCallTimer: null,

      isChanged: {
        inputFrom: true,
        inputTo: true,
      },

      width: 0,
    };
  },

  methods: {
    ...mapActions('vkRequests', ['setAndRequestAuditory']),

    // TODO use vuex for reset, now reset used close button
    resetAuditory() {
      this.minAuditory = Number.parseInt(this.getAuditoryCount.diapazoneFrom, 10);
      this.maxAuditory = Number.parseInt(this.getAuditoryCount.diapazoneTo, 10);

      this.auditoryRequest();
      this.updateSlider();
    },

    setAuditory() {
      this.minAuditory = this.minAuditory === '' ? 0 : Number.parseInt(this.minAuditory, 10);
      this.maxAuditory = this.maxAuditory === '' ? 0 : Number.parseInt(this.maxAuditory, 10);

      if (
        !Number.isInteger(this.minAuditory)
        && !Number.isInteger(this.maxAuditory)
      ) {
        // TODO here mb need add alert window for users
        return;
      }

      this.updateSlider();

      callDebounce();
    },

    updateSlider() {
      newSlidet.setMinValue(Number.parseInt(this.minAuditory, 10));
      newSlidet.setMaxValue(Number.parseInt(this.maxAuditory, 10));
    },

    // for slider callback
    updateValues(min, max) {
      this.minAuditory = min;
      this.maxAuditory = max;
    },
    updateValuesRequest(min, max) {
      this.minAuditory = min;
      this.maxAuditory = max;

      this.auditoryRequest();
    },

    auditoryRequest() {
      const newAuditory = {
        countFrom: this.minAuditory,
        countTo: this.maxAuditory,
      };

      this.setAndRequestAuditory(newAuditory);
      this.$emit('update:resetInput');
    },

    debounce(f, t) {
      return () => {
        const previousCall = this.lastCall;
        this.lastCall = Date.now();

        if (previousCall && this.lastCall - previousCall <= t) {
          clearTimeout(this.lastCallTimer);
        }

        this.lastCallTimer = setTimeout(() => f(), t);
      };
    },

    reCalculateSlider() {
      newSlidet.reset();
    },

    reside() {
      if (this.width !== document.documentElement.clientWidth) {
        this.width = document.documentElement.clientWidth;

        this.reCalculateSlider();
      }
    },
  },

  mounted() {
    window.addEventListener('resize', this.reside);

    callDebounce = this.debounce(this.auditoryRequest, 1000);

    newSlidet = new Slider('my-slider');

    // TODO first setting slider mb need replace for calcForWatch
    // this.minAuditory = this.getAuditoryCount.countFrom;
    // this.maxAuditory = this.getAuditoryCount.countTo;

    this.minAuditory = Number.parseInt(this.getAuditoryCount.diapazoneFrom, 10);
    this.maxAuditory = Number.parseInt(this.getAuditoryCount.diapazoneTo, 10);

    newSlidet.setMin(Number.parseInt(this.minAuditory, 10));
    newSlidet.setMax(Number.parseInt(this.maxAuditory, 10));

    // set listener for slider
    newSlidet.onChange = (min, max) => this.updateValues(min, max);
    newSlidet.didChanged = (min, max) => this.updateValuesRequest(min, max);
  },
  unmounted() {
    window.removeEventListener('resize', this.reside);

    newSlidet = null;
    callDebounce = null;
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}

.slider {
  display: block;
  position: relative;

  height: 36px;
  width: 100%;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.slider-touch-left,
.slider-touch-right {
  display: block;

  position: absolute;

  height: 36px;
  width: 36px;

  padding: 6px;

  z-index: 2;
}

.slider-line {
  position: absolute;
  left: 18px;
  top: 16px;

  width: calc(100% - 36px);
  height: 10px;
  border-radius: 4px;

  z-index: 0;
  overflow: hidden;

  background: #d0d9e3;
  border: 1px solid #a2b3c6;
}

.span2 {
  display: block;

  height: 100%;
  width: 0%;

  border: 1px solid #a2b3c6 !important;
  background: #a2b3c6;
}

.span1 {
  display: block;

  width: 100%;
  height: 100%;

  border: 1px solid #a2b3c6 !important;
  background: #fff;
  border-radius: 50%;
}

.result {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 0 0 0;

  &__text {
    margin: 0 15px;
  }
}

.input {
  display: flex;

  width: 100%;
  height: 25px;

  margin: 0 10px;

  align-items: center;
  font-size: 16px;
  text-align: center;
  opacity: 0.5;

  &:focus {
    opacity: 1;
  }
}

@media (max-width: 1100px) {
  .input {
    margin: 0 3px;
  }
 .result {
  &__text {
    margin: 0 0 0 0;
  }
}
}
</style>
