<template>
  <div class="check-mark" :style="cssVars"></div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '25px',
    },
    height: {
      type: String,
      default: '25px',
    },
    color: {
      type: String,
      default: '#333',
    },
    stroke: {
      type: String,
      default: '5px',
    },
  },
  computed: {
    cssVars() {
      return ({
        '--bg-color': this.color,
        '--line-stroke': this.stroke,
        width: this.width,
        height: this.height,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.check-mark {
  position: relative;

  &::before {
   content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 70%;
    height: 50%;

    border-left: var(--line-stroke) solid var(--bg-color);
    border-bottom: var(--line-stroke) solid var(--bg-color);

    transform: rotate(316deg);
  }
}
</style>
